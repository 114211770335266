import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-6.css';

const PageSix = forwardRef((props, ref) => (
  <>
    <SEO title="Page 6" />
    <main className="page page-reverse page6">
      <div className="page__image-container">
        <StaticImage
          alt="Jimbugs describing his job and workshop"
          aspectRatio={2849 / 3591}
          className="page__image page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          objectFit="contain"
          placeholder="tracedSVG"
          src="../../images/page-6.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            &ldquo;Where is your workshop?&rdquo; Amanda was very curious.
          </p>
          <p>
            &ldquo;It&apos;s under the churchyard. It&apos;s very quiet there and we don&apos;t have to worry
            about waking anyone up.&rdquo;
          </p>
          <p>
            Amanda giggled, while Jimbugs continued,
          </p>
          <p>
            &ldquo;The sockpickers gather socks. They&apos;re made into fuzzerbees, goobles, jigglers and batwams.
            It&apos;s not an easy job as all the socks have to be different. If I take back a sock they already have I
            have to return it the very next day.&rdquo;
          </p>
          <p>
            <strong>
              So that&apos;s why Amanda sometimes found a missing sock in the next wash!
            </strong>
          </p>
        </div>
      </div>
      <PreviousPage to="/page-5" />
      <NextPage to="/page-7" />
    </main>
  </>
));

const PageSixContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageSix ref={textRef} />;
};

export default PageSixContainer;
